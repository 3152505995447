export function up() {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
            $("#up").addClass("active");
        }else {
            $("#up").removeClass("active");
        }
    });
    $('#up').on('click', function(){
        $("html,body").animate({scrollTop:0},500);
    })
}